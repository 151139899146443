.Burger {
    width: 100%;
    margin: auto;
    height: 250px;
    overflow: scroll;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
}

@media(min-width: 500px) and (min-height: 400px) {
    .Burger {
        width: 450px;
        height: 300px;
    }
}

@media(min-width: 500px) and (min-height: 401px) {
    .Burger {
        width: 400px;
        height: 350px;
    }
}

@media(min-width: 1000px) and (min-height: 700px) {
    .Burger {
        width: 700px;
        height: 600px;
    }
}
