.NavigationItem {
    margin: 10px 0;
    display: block;
    width: auto;
}

.NavigationItem a {
    color: #8F5C2C;
    width: 100%;
}

.NavigationItem a:hover,
.NavigationItem a:active {
    color: #40a4c8;
}

@media (min-width: 500px) {
    .NavigationItem {
    margin: 0;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    align-items: center;
}

.NavigationItem a {
    color: white;
    text-decoration: none;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active {
    background-color:  #8F5C2C;
    border-bottom: 4px solid #40A4C8;
    color: white;
}
}